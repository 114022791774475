import { UrlTagMappingDto } from "@closerplatform/spinner-openapi";
import { NoStoredStateError, StorageService } from "@fidget/common/services/storageService";
import { parseQueryString } from "@fidget/common/utils";
import { getRandomArrayItem } from "@fidget/common/utils/arrayHelpers";
import { isValidUrl } from "@fidget/common/utils/isValidUrl";
import { PersistedState } from "../../models/persisted-store";
import { SystemTag } from "@fidget/common/models/tags";
import { Observable } from "../../utils/observable";
import { UserDetails } from "@fidget/common/models/user-details";

// TODO: importing it from spinner-openapi increases bundle size
enum MatchingStrategy {
  WholeDomain = "whole_domain",
  PathRecursively = "path_recursively",
  ExactUrl = "exact_url",
  PathArgs = "path_args"
}

export class TagsService {
  public enabledUrlMappings?: ReadonlyArray<UrlTagMappingDto>;
  public matchedTags: ReadonlyArray<string> = [];
  public urlMatchedTags: ReadonlyArray<string> = [];
  public identifyTags: ReadonlyArray<string> = [];
  public readonly $tag = new Observable<string | undefined>(undefined);

  constructor(private readonly storageService: StorageService<PersistedState>, private readonly location: Location) {
    this.getReturningStateTag().then(tag => {
      if (tag) {
        this.addMatchedTag(tag);
      }
    });
  }

  public setUrlTagMappings(urlTagMappings: ReadonlyArray<UrlTagMappingDto>): void {
    this.enabledUrlMappings = urlTagMappings;
    this.notifyUrlChanged();
  }

  public notifyUrlChanged(): void {
    this.urlMatchedTags = this.enabledUrlMappings ? this.getCurrentUrlMatchedTags(this.enabledUrlMappings) : [];

    this.$tag.value = this.getCurrentTagFromMatched(this.allMatchedTags);
  }

  public notifyExitIntent(): void {
    this.$tag.value = SystemTag.ExitIntent;
  }

  public updateIdentifyTags(userDetails: UserDetails): void {
    this.identifyTags = TagsService.normalizeDetailsToTagArray(userDetails);
  }

  public get allMatchedTags(): ReadonlyArray<string> {
    return [...this.identifyTags, ...this.matchedTags, ...this.urlMatchedTags];
  }

  private static normalizeDetailsToTagArray({ tag, tags }: { tag?: string; tags?: readonly string[] }): ReadonlyArray<string> {
    return [...TagsService.normalizeIdentifyTagToArray(tag), ...TagsService.normalizeIdentifyTagToArray(tags)];
  }

  private static normalizeIdentifyTagToArray(argument: string | ReadonlyArray<string> | undefined): ReadonlyArray<string> {
    if (argument !== undefined) {
      if (argument instanceof Array) {
        return argument;
      }
      if (typeof argument === "string") {
        return [argument];
      }
    }

    return [];
  }

  private addMatchedTag(tag: string): void {
    this.matchedTags = [...this.matchedTags, tag];
  }

  public getCurrentUrlMatchedTags(enabledUrlMappings: ReadonlyArray<UrlTagMappingDto>): ReadonlyArray<string> {
    const isMatchingMapping = (mapping: UrlTagMappingDto): boolean => {
      const { matchingStrategy, urlPattern } = mapping;

      const maybeUrl = isValidUrl(urlPattern) ? new URL(urlPattern) : undefined;

      switch (matchingStrategy) {
        case MatchingStrategy.ExactUrl:
          return this.location.href === maybeUrl?.href;
        case MatchingStrategy.WholeDomain:
          return this.location.origin === maybeUrl?.origin;
        case MatchingStrategy.PathArgs:
          const windowParams = parseQueryString(this.location.search.substring(1));
          const mappingParams = parseQueryString(urlPattern);
          const matchingParam = Object.entries(mappingParams).find(
            ([paramName, paramValue]) => windowParams[paramName] && windowParams[paramName] === paramValue
          );
          return matchingParam !== undefined;
        case MatchingStrategy.PathRecursively:
          return maybeUrl ? this.location.href.startsWith(maybeUrl.href) : false;
        default:
          return false;
      }
    };

    return enabledUrlMappings.filter(isMatchingMapping).map(item => item.tag);
  }

  private getCurrentTagFromMatched(tags: readonly string[]): string | undefined {
    return getRandomArrayItem(tags);
  }

  private async getReturningStateTag(): Promise<SystemTag | undefined> {
    try {
      await this.storageService.getSavedState();
      return SystemTag.ReturningVisitor;
    } catch (e) {
      if (e instanceof NoStoredStateError) {
        return SystemTag.NewVisitor;
      }
    }

    return undefined;
  }
}
