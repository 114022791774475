import { Logger, LogInput, LogLevel, UserLogContext } from "./types";

export class CombinedLogger extends Logger {

  private loggers: Logger[] = [];
  constructor(...loggers: Logger[]) {
    super();
    this.loggers = loggers;
  }

  addLogger(newLogger: Logger) {
    this.loggers = [...this.loggers, newLogger];
  }

  public debug = (data: LogInput, ...args: unknown[]): void => {
    this.loggers.forEach((l) => l.debug(data, ...args));
  };
  public info = (data: LogInput, ...args: unknown[]): void => {
    this.loggers.forEach((l) => l.info(data, ...args));
  };
  public warn = (data: LogInput, ...args: unknown[]): void => {
    this.loggers.forEach((l) => l.warn(data, ...args));
  };
  public error = (data: LogInput, ...args: unknown[]): void => {
    this.loggers.forEach((l) => l.error(data, ...args));
  };
  public setLevel = (level?: LogLevel): void => {
    this.loggers.forEach((l) => l.setLevel(level));
  };
  public setUserLogContext = (context: UserLogContext): void => {
    this.loggers.forEach((l) => l.setUserLogContext(context));
  };
  public clearUserLogContext = (): void => {
    this.loggers.forEach((l) => l.clearUserLogContext());
  };
}
