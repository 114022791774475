export function sequentialize<A extends Array<any>, T>(f: (...args: A) => Promise<T>): typeof f {
  let last: Promise<T> | undefined;

  return function (...args) {
    const prev = last;
    const current = (prev ?? Promise.resolve())
      .catch(() => undefined)
      .then(() => f(...args));
    last = current;
    return current;
  };
}
