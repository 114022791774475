import { staticConfig } from "@fidget/common/config/static-config";
import { StorageSharedStore } from "../store/storageSharedStore";
import { PubSub } from "./pubSub";
import { SafeLocalStorageService } from "./safeLocalStorageService";

export class StorageService<State> extends PubSub {
  private readonly store: StorageSharedStore<State>;
  private readonly isStorageSharedState: (state: any) => state is State;
  private readonly stateLocalStorageKey: string;
  private readonly safeLocalStorage = SafeLocalStorageService.getInstance();
  private readonly events = {
    onStateChange: "onStateChange"
  };

  constructor(
    orgId: string,
    store: StorageSharedStore<State>,
    isStorageSharedState: (state: any) => state is State,
    stateLocalStorageKeyPostfix = `sharedStore`
  ) {
    super();
    this.store = store;
    this.isStorageSharedState = isStorageSharedState;
    this.stateLocalStorageKey = `${staticConfig.localStorageKeyPrefix}:${orgId}:${stateLocalStorageKeyPostfix}`;

    window.addEventListener(
      "storage",
      (ev: StorageEvent) => {
        if (ev.key === this.stateLocalStorageKey) {
          this.checkState(true);
        }
      },
      false
    );
  }

  checkState(emit = false) {
    this.getState()
      .then((state) => {
        if (emit) {
          this.emit(this.events.onStateChange, state);
        }
      })
      .catch((reason) => {
        this.safeLocalStorage.setItem(this.stateLocalStorageKey, this.stringify(this.store.state));
      });
  }

  onStateChange(cb: (state: State) => any) {
    this.on<(state: State) => any>(this.events.onStateChange, cb);
  }

  setState(state: State) {
    this.safeLocalStorage.setItem(this.stateLocalStorageKey, this.stringify(state));
  }

  async getState(getOnlySavedState = false): Promise<State> {
    const storedStateString = this.safeLocalStorage.getItem(this.stateLocalStorageKey);
    if (storedStateString) {
      try {
        const storedState = this.parse(storedStateString);
        if (this.isStorageSharedState(storedState)) {
          return storedState;
        } else {
          throw new CorruptedStateError();
        }
      } catch (e) {
        if (e instanceof CorruptedStateError) {
          throw e;
        }

        throw new NonJsonStateError();
      }
    } else if (!getOnlySavedState) {
      return this.store.state;
    } else {
      throw new NoStoredStateError();
    }
  }

  async getSavedState(): Promise<State> {
    return this.getState(true);
  }

  /* tslint:disable:no-null-keyword */
  protected stringify(obj: any) {
    return JSON.stringify(obj, (k, v) => {
      if (v === undefined) {
        return null;
      }
      return v;
    });
  }

  protected parse(str: string) {
    return (function toObjWithUndefineds(objWithNulls: any): any {
      return objWithNulls !== null && typeof objWithNulls === "object"
        ? Object.assign(
            {},
            ...Object.keys(objWithNulls).map((key) => {
              return {
                [key]:
                  objWithNulls[key] === null ? undefined : toObjWithUndefineds(objWithNulls[key])
              };
            })
          )
        : objWithNulls;
    })(JSON.parse(str));
  }
  /* tslint:enable:no-null-keyword */
}

export class NoStoredStateError extends Error {}
export class NonJsonStateError extends Error {}
export class CorruptedStateError extends Error {}
