import { logger, uuidv4 } from "../utils";

export class SafeLocalStorageService {
  private static instance: SafeLocalStorageService;

  static getInstance() {
    if (!SafeLocalStorageService.instance) {
      SafeLocalStorageService.instance = new SafeLocalStorageService();
    }
    return SafeLocalStorageService.instance;
  }

  isSupported(): boolean {
    try {
      const key = uuidv4();
      localStorage.setItem(key, key);
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      logger.warn("localstorage is not supported");
      return false;
    }
  }

  setItem(key: string, value: string) {
    if (this.isSupported()) {
      localStorage.setItem(key, value);
    }
  }

  getItem(key: string) {
    return this.isSupported() ? localStorage.getItem(key) : undefined;
  }

  removeItem(key: string) {
    if (this.isSupported()) {
      localStorage.removeItem(key);
    }
  }

  clear() {
    if (this.isSupported()) {
      localStorage.clear();
    }
  }
}
