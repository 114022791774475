export const injectCSS = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const head = document.querySelector("head");
    if (!head) {
      reject();
      throw new Error("no head element");
    }
    const link = document.createElement("link");
    link.setAttribute("type", "text/css");
    link.rel = "stylesheet";
    link.href = url;
    head.prepend(link);
    link.onload = () => {
      resolve();
    };
  });
