import { Language } from "@fidget/common/models/language";

export function setTabTitle(language: Language, isUnread: boolean, defaultTitle: string, intervalTimer: number | undefined) {
  let counter = false;
  const tabTitleNotification = language === Language.PL ?
    "Nowa wiadomość" : "New message";

  if (!intervalTimer && isUnread) {
    intervalTimer = window.setInterval(() => {
      document.title = counter ?
        tabTitleNotification :
        defaultTitle;
      counter = !counter;
    }, 1000);
  } else if (intervalTimer && !isUnread) {
    clearInterval(intervalTimer);
    intervalTimer = undefined;
    document.title = defaultTitle;
  }
  return intervalTimer;
}
