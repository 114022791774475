interface Wrapper<T> {
  value: T | undefined;
}

export function lazy<T>(init: () => T): T {
  const handler: ProxyHandler<Wrapper<T>> = {
    get: (w: Wrapper<T>, name: PropertyKey) => {
      if (!w.value) {
        w.value = init();
      }
      return (w.value as any)[name];
    },
    set: (w: Wrapper<T>, name: PropertyKey, value: any) => {
      if (!w.value) {
        w.value = init();
      }
      (w.value as any)[name] = value;
      return true;
    }
  };
  const wrapper: Wrapper<T> = {
    value: undefined
  };
  return new Proxy(wrapper, handler) as any as T;
}
