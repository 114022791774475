import React from "react";
import ReactDOM from "react-dom";
import { $$ } from "@fidget/common/utils/domHelper";
import closerLogoAnimationPaths from "../assets/closerLogoAnimation.paths.svg";
import { staticConfig } from "@fidget/common/config/static-config";
import styles from "../assets/view.css";
import { ProactiveMessagesContainer } from "../containers/proactiveMessagesContainer";
import { ProactiveMessagesService } from "../services/proactive-messages/proactive-messages.service";
import { ViewStyles } from "../view/viewStyles";
import { View } from "../view/view";
import { ViewTexts } from "../view/viewTexts";
import { ProxyIframe } from "@fidget/common/services/windowProxyFactory";
import { logger } from "@fidget/common/utils";

export class ViewFactory {
  private externalChatContainer?: HTMLElement;

  constructor(
    private foreWindow: ProxyIframe,
    private proactiveMessagesService: ProactiveMessagesService,
    private viewTexts: ViewTexts,
    private showMainButton: boolean,
    externalContainerName?: string
  ) {
    this.externalChatContainer = externalContainerName
      ? ViewFactory.fetchExternalContainer(externalContainerName)
      : undefined;
  }

  create(
    isChatVisible: boolean,
    onMainButtonClick: () => void,
    onCloseIncomingMessagesButtonClick: () => void
  ): View {
    const topLevelContainer = ViewFactory.getMainContainer();

    const mainButtonClassNames = [styles["main-button"]].concat(
      this.externalChatContainer && isChatVisible ? [styles.hidden] : []
    );

    const floatingChatWrapperClassNames = [styles["chat-wrapper"]].concat(
      this.externalChatContainer ? [styles.hidden] : []
    );

    const {
      mainWrapper,
      mainButton,
      messageListContainer,
      proactiveMessagesContainer,
      closeButton,
      floatingChatWrapper,
      mainButtonTooltip,
      svgCloser,
      mainButtonAvatar,
    } = $$(
      "div",
      { classList: [styles["main-wrapper"]], ref: "mainWrapper" },
      [
        $$("div", { classList: mainButtonClassNames, ref: "mainButton" }, [
          $$(
            "div",
            {
              classList: [styles["default-logo"]],
              id: ViewStyles.mainButtonOpenedId,
            },
            [
              $$("div", {
                classList: [styles["main-button-tooltip"]],
                ref: "mainButtonTooltip",
              }),
              $$("div", { ref: "svgCloser" }),
              $$("div", {
                classList: [styles["main-button-avatar"]],
                ref: "mainButtonAvatar",
              }),
            ]
          ),
          $$("div", {
            classList: [styles["close-icon"]],
            id: ViewStyles.mainButtonClosedId,
          }),
        ]),
        $$(
          "div",
          { classList: [styles.messages], ref: "messageListContainer" },
          [
            $$(
              "div",
              { classList: [styles["close-button"]], ref: "closeButton" },
              [$$("i")]
            ),
          ]
        ),
        $$("div", {
          classList: [styles["proactive-messages-container"]],
          ref: "proactiveMessagesContainer",
        }),
      ].concat(
        !this.externalChatContainer
          ? [
              $$("div", {
                classList: floatingChatWrapperClassNames,
                ref: "floatingChatWrapper",
              }),
            ]
          : []
      )
    );

    topLevelContainer.appendChild(mainWrapper!);
    mainButton?.addEventListener("click", onMainButtonClick);
    if (mainButton) {
      logger.debug(`Show main button is set to ${this.showMainButton}`);
      mainButton.style.display = this.showMainButton ? "block" : "none";
    }
    svgCloser!.outerHTML = closerLogoAnimationPaths;

    closeButton?.addEventListener("click", (ev) => {
      ev.stopPropagation();
      onCloseIncomingMessagesButtonClick();
    });
    const iframeContainer = this.externalChatContainer || floatingChatWrapper;
    const foreIframe = this.externalChatContainer
      ? this.setForeIframeContainer(this.externalChatContainer)
      : this.setForeIframeContainer(floatingChatWrapper!);

    const isChatFloating = !this.externalChatContainer;

    const view = new View(
      this.viewTexts,
      isChatFloating,
      topLevelContainer,
      messageListContainer!,
      mainButton!,
      mainButtonTooltip!,
      mainButtonAvatar!,
      mainWrapper!,
      iframeContainer as HTMLDivElement,
      foreIframe
    );
    view.setChatIsVisible(isChatVisible);
    this.renderProactiveMessagesContainer(proactiveMessagesContainer!);

    return view;
  }

  private renderProactiveMessagesContainer(
    proactiveMessagesContainer: HTMLElement
  ) {
    const proactiveMessagesService = this.proactiveMessagesService;
    ReactDOM.render(
      React.createElement(ProactiveMessagesContainer, {
        proactiveMessagesService,
      }),
      proactiveMessagesContainer
    );
  }

  private setForeIframeContainer(el: Element): HTMLIFrameElement {
    const iframeContainer = el as HTMLDivElement;
    const foreIframe = this.foreWindow.iframe;
    foreIframe.className = styles.iframe;
    iframeContainer.appendChild(foreIframe);
    iframeContainer.setAttribute("style", ViewStyles.iFrameBoxShadowString);
    return foreIframe;
  }

  private static getMainContainer(): HTMLElement {
    const mainContainerFromDocument = document.getElementById(
      staticConfig.widgetContainerId
    );
    if (mainContainerFromDocument) {
      mainContainerFromDocument.style.display = "none";
      return mainContainerFromDocument;
    }

    return ViewFactory.getNewMainContainer();
  }

  private static getNewMainContainer(): HTMLElement {
    const mainContainer = document.createElement("div");
    mainContainer.id = staticConfig.widgetContainerId;
    document.body.appendChild(mainContainer);
    mainContainer.style.display = "none";

    return mainContainer;
  }

  private static fetchExternalContainer(containerName: string): HTMLElement {
    const maybeElem = document.querySelector<HTMLElement>(containerName);
    if (maybeElem) {
      return maybeElem;
    } else {
      throw new Error("Failed to fetch external container");
    }
  }
}
