import { InterWindowRequestType } from "../models/interWindowRequestType";
import { getOrigin, getUniqueId, logger, makeQueryString, noop, parseQueryString } from "../utils";
import stringify from "safe-json-stringify";

interface Request {
  id: string;
  type: InterWindowRequestType;
  payload?: any;
}

interface Response {
  requestId: string;
  type: InterWindowRequestType;
  payload?: any;
  error?: string;
}

function isRequest(data: any): data is Request {
  return (data as Request).type !== undefined && (data as Request).id !== undefined;
}

function isResponse(data: any): data is Response {
  return (data as Response).type !== undefined && (data as Response).requestId !== undefined;
}

function makeResponseId(type: InterWindowRequestType, requestId: string) {
  return `${type}:${requestId}`;
}

export class WindowProxy {
  thatWindow?: Window;
  thatWindowOrigin?: string;

  handlers: { [type: string]: Array<(payload: any) => Promise<any>> } = {};
  responses: { [id: string]: any } = {};
  stashed: Array<MessageEvent> = [];

  RESPONSE_TIMEOUT = 5000;
  RETRY_TIMEOUT = 5000;

  constructor(thatWindow?: Window, thatWindowOrigin?: string) {
    if (thatWindow) {
      if (!thatWindowOrigin) {
        throw new Error("`thatWindowOrigin` parameter is required");
      }
      this.thatWindow = thatWindow;
      this.thatWindowOrigin = thatWindowOrigin;
    }

    window.addEventListener(
      "message",
      (ev) => this.onWindowMessage(ev).catch(logger.debug).then(noop),
      false
    );
  }

  clearHandlers() {
    this.handlers = {};
  }

  onWindowMessage(event: MessageEvent): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.thatWindow) {
        this.stashed.push(event);
        return resolve();
      }
      if (event.source !== this.thatWindow) {
        return reject(`unknown source says: ${stringify(event.data)}`);
      }
      try {
        const data = JSON.parse(event.data);

        if (isResponse(data)) {
          logger.debug(`Interwindow data is response ${stringify(data)}`);
          this.responses[makeResponseId(data.type, data.requestId)] =
            "error" in data ? { error: data.error } : { payload: data.payload };
        }

        if (isRequest(data)) {
          logger.debug(`Interwindow data is request ${stringify(data)}`);
          if (data.type in this.handlers) {
            this.handlers[data.type]!.forEach(
              (handler: (data: { [key: string]: string }) => any) => {
                handler(data.payload)
                  .then((response: { [key: string]: string } | Promise<void>) => {
                    (event.source as Window).postMessage(
                      JSON.stringify({
                        type: data.type,
                        requestId: data.id,
                        payload: response
                      }),
                      event.origin
                    );
                    resolve(response as Promise<void>);
                  })
                  .catch((error: string) => {
                    (event.source as Window).postMessage(
                      JSON.stringify({
                        type: data.type,
                        requestId: data.id,
                        error: error || ""
                      }),
                      event.origin
                    );
                    reject(error);
                  });
              }
            );
          } else {
            event.source.postMessage(
              JSON.stringify({
                type: data.type,
                requestId: data.id,
                error: `no handler for request type: ${data.type}`
              }),
              event.origin
            );
            logger.warn(`Interwindow no handler for request ${data.type}:${data.id}`);
            reject(`no handler for request type: ${data.type}`);
          }
        }
      } catch (ignore) {
        noop();
      }
    });
  }

  sendRequest<Q, A>(type: InterWindowRequestType, payload?: Q, retry = false): Promise<A> {
    return new Promise((resolve, reject) => {
      if (!this.thatWindow) {
        return reject("no window to send request to");
      }
      const id = getUniqueId();
      logger.info(`Interwindow send request ${type}:${id}`);
      const responseId = makeResponseId(type, id);
      const jsonPayload = JSON.stringify({ type, id, payload }, (k, v) =>
        /* tslint:disable-next-line:no-null-keyword */
        v === undefined ? null : v
      );
      if (!this.thatWindowOrigin) {
        logger.error("Posting message with empty origin");
      }
      this.thatWindow.postMessage(jsonPayload, this.thatWindowOrigin || "");

      const responseCheckInterval = setInterval(() => {
        if (responseId in this.responses) {
          const response = this.responses[responseId];
          delete this.responses[responseId];
          clearInterval(responseCheckInterval);
          clearTimeout(responseTimeout);
          if ("error" in response) {
            if (retry) {
              logger.warn(`Interwindow request failed, retrying ${type}:${id} ${response.error}`);
              setTimeout(
                () => this.sendRequest<Q, A>(type, payload, false).then(resolve).catch(reject),
                this.RETRY_TIMEOUT
              );
            } else {
              logger.error(`Interwindow request failed ${type}:${id} ${response.error}`);
              reject(response.error);
            }
          } else {
            logger.info(`Interwindow receive response ${type}:${id}`);
            resolve(response.payload as A);
          }
        }
      }, 100);
      const responseTimeout = setTimeout(() => {
        clearInterval(responseCheckInterval);
        clearTimeout(responseTimeout);
        logger.error(`Interwindow request expired ${type}:${id}`);
        reject(`request ${responseId} expired`);
      }, this.RESPONSE_TIMEOUT);
    });
  }

  onRequest<Q, A>(type: InterWindowRequestType, fn: (payload: Q) => Promise<A>): void {
    logger.info(`Interwindow request handler registered: ${type}`);
    this.handlers[type] = (this.handlers[type] ?? []).concat([fn]);
  }

  unstash() {
    if (this.thatWindow) {
      this.stashed
        .filter((ev) => ev.source === this.thatWindow)
        .forEach((ev) => this.onWindowMessage(ev).catch(logger.debug));
      this.stashed = [];
    }
  }
}

export interface ProxyIframe {
  proxy: WindowProxy;
  iframe: HTMLIFrameElement;
}

export class WindowProxyFactory {
  openIframe(url: string): ProxyIframe {
    const [baseUrl, ...queryParts] = url.split("?");
    const iframeSrc =
      baseUrl +
      "?" +
      makeQueryString({
        ...parseQueryString(queryParts.join("?")),
        origin: window.location.origin + window.location.pathname
      });
    const proxy = new WindowProxy();
    const iframe = document.createElement("iframe");
    iframe.setAttribute("allow", "microphone; camera; fullscreen");
    iframe.setAttribute("allowfullscreen", "true");
    iframe.src = iframeSrc;
    iframe.id = "iframe_closer";
    proxy.thatWindowOrigin = getOrigin(url);
    iframe.onload = () => {
      if (iframe.style) {
        iframe.style.display = "none";
      }
      proxy.thatWindow = iframe.contentWindow!;
      proxy.unstash();
    };
    return { proxy, iframe };
  }

  fromWindow(thatWindow: Window, thatWindowOrigin: string): WindowProxy {
    return new WindowProxy(thatWindow, thatWindowOrigin);
  }
}

export const windowProxyFactory = new WindowProxyFactory();
