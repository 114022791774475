interface Attributes {
  classList?: Array<string>;
  src?: string;
  id?: string;
  ref?: string;
}

interface NamedNodes<T> {
  $: T;
  [name: string]: T;
}

export function domHelper(
  tagName: string,
  attributes: Attributes = {},
  children: Array<NamedNodes<Node>> = []
): NamedNodes<HTMLElement> {
  const el = document.createElement(tagName);
  if (attributes.classList && attributes.classList.length) {
    el.setAttribute("class", attributes.classList.join(" "));
  }
  if (attributes.src) {
    el.setAttribute("src", attributes.src);
  }
  if (attributes.id) {
    el.id = attributes.id;
  }
  return Object.assign(
    {},
    ...children.map((child) => {
      el.appendChild(child.$);
      return {
        ...child,
        $: undefined
      };
    }),
    attributes.ref ? { $: el, [attributes.ref]: el } : { $: el }
  );
}

export const $$ = domHelper;

export const onEnter = (fn: () => void) => {
  const prop: Pick<React.HTMLProps<HTMLInputElement>, "onKeyUp"> = {
    onKeyUp: (e) => {
      if (e.key === "Enter") {
        fn();
      }
    }
  };

  return prop;
};
