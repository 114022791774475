export class PubSub {
  callbacks: { [eventName: string]: Array<any> } = {};

  on<T>(eventName: string, cb: T): () => void {
    if (!(eventName in this.callbacks)) {
      this.callbacks[eventName] = [];
    }

    this.callbacks[eventName]!.push(cb);
    return () =>
      (this.callbacks[eventName] = this.callbacks[eventName]!.filter((fn: any) => fn !== cb));
  }

  protected emit(eventName: string, ...args: Array<any>) {
    if (eventName in this.callbacks) {
      this.callbacks[eventName]!.forEach((cb: any) => {
        if (cb && {}.toString.call(cb) === "[object Function]") {
          cb(...args);
        }
      });
    }
  }
}
