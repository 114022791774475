import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { ProactiveMessage } from "../components/proactiveMessage/proactiveMessage";
import styles from "./proactiveMessagesContainer.css";
import { ProactiveMessagesService } from "../services/proactive-messages/proactive-messages.service";

const DISMISS_TIMEOUT = 400;
interface Props {
  proactiveMessagesService: ProactiveMessagesService;
}

export const ProactiveMessagesContainer = (props: Props) => {
  const [message, dismissed, dismissMessages] = useProactiveMessagesState(props);

  const render = () => !message ? <></> : (
    <div className={classNames(styles["proactive-msgs-container"], { [styles.dismissed]: dismissed })}>
      <ProactiveMessage text={message} onClick={dismissMessages} />
    </div>
  );

  return render();
};

type ProactiveMessagesStateType = [string | undefined, boolean, () => void];

const useProactiveMessagesState = ({ proactiveMessagesService }: Props): ProactiveMessagesStateType => {
  const [message, setMessage] = useState<string>();
  const [dismissed, setDismissed] = useState<boolean>(false);
  const [dismissedTimer, setDismissedTimer] = useState<number>();

  const setListeners = () => {
    proactiveMessagesService.onChatVisible(() => dismissMessages());
    proactiveMessagesService.onUnreadChatMessages(() => dismissMessages());

    proactiveMessagesService.onNewMatching(maybeMessage => {
      if (maybeMessage) {
        setMessage(previousMessage => {
          if (previousMessage && previousMessage !== maybeMessage) {
            setDismissed(true);
            displayNewMessageAfterTimeout(maybeMessage);
            return previousMessage;
          }

          return maybeMessage;
        });
      }
    });
  };

  useEffect(() => {
    setListeners();

    setMessage(proactiveMessagesService.getCurrentTagMatchedMessage());

    return () => {
      if (dismissedTimer) {
        clearTimeout(dismissedTimer);
      }
    };
  }, []);

  const displayNewMessageAfterTimeout = (newMessage: string) => {
    const timeout = window.setTimeout(() => {
      setMessage(newMessage);
      setDismissed(false);
    }, DISMISS_TIMEOUT);

    setDismissedTimer(timeout);
  };

  const dismissMessages = () => {
    setDismissed(true);
    const timer = window.setTimeout(() => {
      setMessage(undefined);
      setDismissed(false);
      setDismissedTimer(undefined);
    }, DISMISS_TIMEOUT);

    setDismissedTimer(timer);
  };

  return [message, dismissed, dismissMessages];
};
