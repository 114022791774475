import { Language } from "../models/language";

export const parseLanguage = (lang?: string): Language | undefined => {
  const lowercaseLang = lang?.toLowerCase();
  return Object.values(Language).includes(lowercaseLang as Language)
    ? (lowercaseLang as Language)
    : undefined;
};

export const getLanguageFromLocale = (locale: string): Language =>
  parseLanguage(locale.substr(0, 2)) || Language.EN;

export const getLocaleFromBrowser = (languageOverride?: Language) => {
  switch (languageOverride || navigator.language.split("-")[0]) {
    case "pl":
      return "pl-PL";
    case "ka":
      return "ka-GE";
    case "es":
      return "es-ES";
    default:
      return "en-GB";
  }
};
