import { WorkingHours } from "@closerplatform/spinner-openapi";
import {
  DAY_MILLIS,
  DAY_SECONDS,
  HOUR_MILLIS,
  MINUTE_MILLIS,
  SECOND_MILLIS
} from "@fidget/common/utils/timeUtils";
import { lazy } from "./lazy";
import { intl } from "../i18n/TypedIntl";
import { WorkingDay } from "@fidget/common/models/workingDay";
import { startOfISOWeek, addSeconds, getUnixTime } from "date-fns";
import { rangeFromToPositive } from "./arrayHelpers";

export const DAY_NAMES = lazy(() => {
  return rangeFromToPositive(5, 12).map((day) =>
    intl.formatDate(new Date(1970, 0, day), { weekday: "long" })
  );
});

export type WorkingInterval = { from: number; to: number; };
export type WorkingWeek = Array<WorkingInterval>;

export const getWorkingWeekIntervals = (normalizedHours: ReadonlyArray<WorkingHours>): WorkingWeek => {
  const result: WorkingWeek = [...Array(7)].map((_) => ({ from: 0, to: DAY_SECONDS }));
  const workingHours = [...normalizedHours]
    .sort((a, b) => a.offsetSeconds - b.offsetSeconds)
    .map((n, i) => {
      const beginOfWeek = startOfISOWeek(new Date());
      const withOffset = addSeconds(beginOfWeek, n.offsetSeconds);
      const forCurrentWeek = getUnixTime(withOffset) * SECOND_MILLIS;

      return {
        ...n,
        day: i,
        offsetSeconds: forCurrentWeek
      };
    });
  workingHours.forEach((d) => {
    result[d.day] = {
      from: d.offsetSeconds,
      to: d.offsetSeconds + d.durationSeconds * SECOND_MILLIS
    };
  });
  return result;
};

export const getEmptyWorkingHours = (): readonly WorkingHours[] => {

  return [
    { offsetSeconds: 0, durationSeconds: 0 },
    { offsetSeconds: DAY_SECONDS, durationSeconds: 0 },
    { offsetSeconds: 2 * DAY_SECONDS, durationSeconds: 0 },
    { offsetSeconds: 3 * DAY_SECONDS, durationSeconds: 0 },
    { offsetSeconds: 4 * DAY_SECONDS, durationSeconds: 0 },
    { offsetSeconds: 5 * DAY_SECONDS, durationSeconds: 0 },
    { offsetSeconds: 6 * DAY_SECONDS, durationSeconds: 0 }
  ];
};

export const getEmptyWorkingWeek = (): WorkingWeek => {
  return getWorkingWeekIntervals(getEmptyWorkingHours());
};

export const getEmptyWorkingDays = (): WorkingDay[] => {
  return getEmptyWorkingWeek().map((day, i) => new WorkingDay(DAY_NAMES[i]!, day));
};

export const calculateWorkingHours = (workingWeek: WorkingWeek): Array<WorkingHours> => {
  const normalizedHours: Array<WorkingHours> = workingWeek.map((day, i) => {
    const normalizedDay: WorkingHours = {
      offsetSeconds: Math.floor(normalizeFrom(day.from, i) / SECOND_MILLIS),
      durationSeconds: Math.floor((day.to - day.from) / SECOND_MILLIS)
    };
    return normalizedDay;
  });
  return normalizedHours;
};

const normalizeFrom = (from: number, day: number): number => {
  const fromAsDate = new Date(from - new Date().getTimezoneOffset() * 60 * 1000);
  return (
    fromAsDate.getUTCHours() * HOUR_MILLIS +
    fromAsDate.getUTCMinutes() * MINUTE_MILLIS +
    day * DAY_MILLIS
  );
};

export const currentlyInWorkingHours = (workingHours: ReadonlyArray<WorkingHours>): boolean => {
  const localWorkingIntervals = getWorkingWeekIntervals(workingHours);
  const localDate = new Date();
  const currentDayIndex = (localDate.getUTCDay() + 6) % 7; // index converted to starting from Monday instead of Sunday
  const todaysWorkingInterval = localWorkingIntervals[currentDayIndex];

  return (
    localDate.getTime() >= todaysWorkingInterval!.from &&
    localDate.getTime() <= todaysWorkingInterval!.to
  );
};
