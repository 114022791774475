import { WindowWithScript } from "./models/window";
import { CloserApi } from "./closer-api";
import { logger } from "@fidget/common/utils";
import { getVersion } from "@fidget/common/utils/version";

export class MainModule {
  constructor(private window: WindowWithScript) {}

  bootstrap(): void {
    const { closer } = this.window;

    logger.debug(`Closer: bootstrapping resident version ${getVersion()}`);

    if (!closer) {
      throw new Error("Closer: closer object is not accessible in the window");
    }

    if (!closer.q) {
      throw new Error("Closer: closer.q queue is not accessible");
    }

    const closerApi = new CloserApi(closer.q || [], closer.scriptUrl);
    // Apply the real closer api instead of the closer loading script pasted by the client
    this.window.closer = closerApi;

    if (closer.q.length > 0) {
      const [maybeInitCall] = closer.q;
      // If first element of api calls queue is `init` or `reinit` or 'deinit' - process it
      switch (maybeInitCall?.method) {
        case "init": {
          closerApi.init(maybeInitCall.args[0]);
          break;
        }
        case "reinit": {
          closerApi.reinit(maybeInitCall.args[0]);
          break;
        }
        case "deinit": {
          closerApi.deinit(maybeInitCall.args[0]);
          break;
        }
        default: {
          throw new Error(`Closer: first call has to be one of init/reinit/deinit instead of ${maybeInitCall?.method}`);
        }
      }
    }
  }
}
