import { logger } from "./logger";

export const onDOMContentLoaded = (fn: () => any): void => {
  const render = () => {
    if (!document.body) {
      return logger.error("no body element");
    }
    fn();
  };
  const DOMContentLoaded =
    document.readyState === "interactive" || document.readyState === "complete";
  if (DOMContentLoaded) {
    render();
  } else {
    document.addEventListener("DOMContentLoaded", render);
  }
};
