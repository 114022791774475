import { LimitedProactiveMsgDto, WorkingHours } from "@closerplatform/spinner-openapi";
import { currentlyInWorkingHours } from "@fidget/common/utils/getWorkingHours";
import { Model } from "../../models/model";
import { Language } from "@fidget/common/models/language";
import { ForeWindowRequestsService } from "../fore-window-requests.service";
import { TagsService } from "../tags/tags.service";
import { getRandomArrayItem } from "@fidget/common/utils/arrayHelpers";
import { SystemTag } from "@fidget/common/models/tags";
import { Observable } from "../../utils/observable";

export class ProactiveMessagesService {
  private $enabledProactiveDtos = new Observable<ReadonlyArray<LimitedProactiveMsgDto>>([]);

  constructor(private model: Model, private tagService: TagsService, private foreWindowRequests: ForeWindowRequestsService) {}

  public setProactiveMessages(proactiveMessages: ReadonlyArray<LimitedProactiveMsgDto>): void {
    this.$enabledProactiveDtos.value = proactiveMessages;
  }

  public getCurrentTagMatchedMessage(): string | undefined {
    const enabledProactiveMessages = this.$enabledProactiveDtos.value;
    const { language, $orgConfig: orgConfig } = this.model;
    const tag = this.tagService.$tag.value;
    const matchedTags = this.tagService.allMatchedTags;
    const matchedTagsWithCurrent = tag && !matchedTags.includes(tag) ? [...matchedTags, tag] : matchedTags;

    return tag && enabledProactiveMessages && orgConfig.value
      ? this.getMatchingMessage(tag, matchedTagsWithCurrent, enabledProactiveMessages, language, orgConfig.value.workingHours)
      : undefined;
  }

  public onNewMatching(fn: (message: string | undefined) => void): void {
    this.tagService.$tag.on(tag => fn(this.getCurrentTagMatchedMessage()));
    this.$enabledProactiveDtos.on(() => fn(this.getCurrentTagMatchedMessage()));
  }

  public onChatVisible(fn: () => void): void {
    this.model.$chatIsVisible.on(isVisible => isVisible && fn());
  }

  public onUnreadChatMessages(fn: () => void): void {
    this.foreWindowRequests.onUnreadMessagesChanged(isUnread => {
      if (isUnread) {
        fn();
      }
      return Promise.resolve();
    });
  }

  public getMatchingMessage(
    currentTag: string,
    matchedTags: readonly string[],
    enabledProactiveDtos: readonly LimitedProactiveMsgDto[],
    language: Language,
    workingHours: readonly WorkingHours[]
  ): string | undefined {
    const isInWorkingHours = this.isInWorkingHours(workingHours);
    const matchingDtos = enabledProactiveDtos
      .filter(dto => dto.langTag.includes(language))
      .filter(dto => dto.displayOutsideWorkingHours || isInWorkingHours)
      .filter(dto => matchedTags.includes(dto.tag));

    return this.determineMessage(currentTag, matchingDtos);
  }

  private isInWorkingHours(workingHours: readonly WorkingHours[]): boolean {
    return currentlyInWorkingHours(workingHours);
  }

  private determineMessage(currentTag: string, matchingDtos: readonly LimitedProactiveMsgDto[]): string | undefined {
    const dtoForCurrentTag = matchingDtos.find(dto => dto.tag === currentTag);

    return dtoForCurrentTag
      ? dtoForCurrentTag.text
      : currentTag !== SystemTag.ExitIntent
      ? getRandomArrayItem(matchingDtos.map(dto => dto.text))
      : undefined;
  }
}
