import { createIntl } from "react-intl";
import { Language } from "../models/language";
import { IntlService } from "./IntlService";
import { CountriesTransaltions } from "./locales/en/Countries";
import { StringsTransaltions } from "./locales/en/Strings";

export type Translations = StringsTransaltions & CountriesTransaltions;
export type IntlValueType = string | number | null | undefined;

export const intl = new IntlService(createIntl({ locale: Language.EN }));
