import {
  IntlShape,
  createIntl,
  FormatNumberOptions,
  FormatRelativeTimeOptions,
  FormatDateOptions,
  Formatters
} from "react-intl";
import { L10nId } from ".";
import { IntlValueType, Translations } from "./TypedIntl";
import { differenceInDays, startOfDay } from "date-fns";

export class IntlService {
  constructor(public intl: IntlShape) {}

  public formatDate(value: string | Date | number, opts?: FormatDateOptions) {
    return this.intl.formatDate(value, opts);
  }
  public formatTime(value: string | Date | number, opts?: FormatDateOptions) {
    return this.intl.formatTime(value, opts);
  }

  public formatNumber(value: number, opts?: FormatNumberOptions) {
    return this.intl.formatNumber(value, opts);
  }
  public formatRelative(
    value: number,
    unit?: Parameters<ReturnType<Formatters["getRelativeTimeFormat"]>["format"]>[1],
    opts?: FormatRelativeTimeOptions
  ) {
    return this.intl.formatRelativeTime(value, unit, opts);
  }
  public formatRelativeDays(date: Date | number): string {
    return this.formatRelative(differenceInDays(startOfDay(date), startOfDay(Date.now())), "day", {
      numeric: "auto"
    });
  }

  public formatMessage(id: L10nId, values?: { [key: string]: IntlValueType }): string {
    return this.intl.formatMessage({ id: id.toString() }, values);
  }

  public formatPrice(value: number, currency?: string): string {
    return this.intl.formatNumber(value, {
      style: "currency",
      currency: currency || "EUR",
      minimumFractionDigits: 2
    });
  }

  public setMessages(locale: string, messages: Translations) {
    this.intl = createIntl({ locale, messages });
  }
}
