export type Observer<K> = (val: K) => void;

export class Observable<T> {

  private observers: ReadonlyArray<Observer<T>> = [];

  constructor(
    private currentValue: T
    ) {
  }

  get value(): T {
    return this.currentValue;
  }

  set value(value: T) {
    this.currentValue = value;
    this.notifyObservers(value);
  }

  public on(observer: Observer<T>) {
    this.observers = [...this.observers, observer];
    observer(this.currentValue);
  }

  private notifyObservers(value: T): void {
    this.observers.forEach(observer => observer(value));
  }
}
