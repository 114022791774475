import { StorageSharedStore } from "@fidget/common/store/storageSharedStore";
import { match } from "@fidget/common/utils/match";

export interface PersistedState {
  chatIsVisible: boolean;
}

export function isPersistedState(state: any): state is PersistedState {
  return match(state, persistedStore.defaultState);
}

class PersistedStore extends StorageSharedStore<PersistedState> {

  constructor(persistedState: PersistedState) {
    super(persistedState);
  }
}

export const defaultPersistedState: PersistedState = {
  chatIsVisible: false,
};

export const persistedStore = new PersistedStore(defaultPersistedState);
