import classNames from "classnames";
import React, { useState } from "react";
import styles from "./proactiveMessage.css";

interface Props {
  text: string;
  onClick: () => void;
}

export const ProactiveMessage = ({ text, onClick }: Props) => {
  const [closeIconDisplayed, setCloseIconDisplayed] = useState<boolean>(false);

  const onMouseEnter = () => setCloseIconDisplayed(true);
  const onMouseLeave = () => setCloseIconDisplayed(false);

  return (
    <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {closeIconDisplayed && <div className={styles["close-cross-container"]}>
        <i className={classNames("icon-close-cross", styles["close-cross"])} onClick={onClick}></i>
      </div>}
      <div className={styles["proactive-message-content"]}>
        {text}
      </div>
    </div>
  );
};
