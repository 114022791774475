export interface UserLogContext {
  orgId: string;
  userId?: string;
  roomId?: string;
}

export interface LogData extends Partial<UserLogContext> {
  /** Human readable message */
  message: string;
  error?: Error | unknown;

  requestId?: string;
  timestamp?: number;
}
export type LogInput = LogData | string;

export enum LogLevel {
  error = 4,
  warn = 3,
  info = 2,
  debug = 1,
}

export abstract class Logger {
  protected parseLogInput(input: LogInput): LogData {
    if (typeof input === "string") {
      return { message: input, timestamp: Date.now() };
    }

    return {
      ...input,
      timestamp: Date.now(),
    };
  }
  abstract debug(data: LogInput, ...args: unknown[]): void;
  abstract info(data: LogInput, ...args: unknown[]): void;
  abstract warn(data: LogInput, ...args: unknown[]): void;
  abstract error(data: LogInput, ...args: unknown[]): void;

  abstract setLevel(level?: LogLevel): void;

  abstract setUserLogContext(context: UserLogContext): void;
  abstract clearUserLogContext(): void;
}
