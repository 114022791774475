// tslint:disable:no-console
import { Logger, LogInput, LogLevel, UserLogContext } from "./types";

export class ConsoleLogger extends Logger {
  private level = LogLevel.info;

  private parseInput(arg: LogInput, ...args: unknown[]): (string | Error | unknown)[] {
    const data = this.parseLogInput(arg);

    return [data.message, data.error, ...args];
  }

  public debug = (data: LogInput, ...args: unknown[]): void => {
    if (this.level <= LogLevel.debug) {
      console.debug(...this.parseInput(data, ...args));
    }
  };

  public info = (data: LogInput, ...args: unknown[]): void => {
    if (this.level <= LogLevel.info) {
      console.info(...this.parseInput(data, ...args));
    }
  };

  public warn = (data: LogInput, ...args: unknown[]): void => {
    if (this.level <= LogLevel.warn) {
      console.warn(...this.parseInput(data, ...args));
    }
  };

  public error = (data: LogInput, ...args: unknown[]): void => {
    if (this.level <= LogLevel.error) {
      console.error(...this.parseInput(data, ...args));
    }
  };

  public setLevel(level?: LogLevel): void {
    this.level = level ?? LogLevel.info;
  }

  setUserLogContext(context: UserLogContext): void {
    // Ignore context in console logs
  }
  clearUserLogContext(): void {
    // Ignore context in console logs
  }
}
