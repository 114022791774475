import { makeQueryString } from "../utils/makeQueryString";
import { ProxyIframe, windowProxyFactory } from "./windowProxyFactory";
import { Language } from "@fidget/common/models/language";

export interface ForeWindowQuery {
  baseUrl: string;
  orgId: string;
  lang: Language;
  cc?: string; // continue conversation token
  jm?: string; // join meeting token
  cm?: string; // cancel meeting token
  acc?: string; // auto client calling
  aac?: string; // always auto call
  cac?: string; // call on assginee changed
  ban?: string; // bot first assignee name
  cnam?: string; // call to new assignee message
  fnu?: string; // force new user
  tid?: string; // trace id for logger
}

export function openForeWindow(query: ForeWindowQuery): ProxyIframe {
  return windowProxyFactory.openIframe(
    query.baseUrl +
      "/fore.html?" +
      makeQueryString({
        ...query,
        origin: window.location.origin + window.location.pathname
      })
  );
}

export function disallowWindowClosing() {
  window.addEventListener("beforeunload", blockUnload);
}

export function allowWindowClosing() {
  window.removeEventListener("beforeunload", blockUnload);
}

function blockUnload(event: BeforeUnloadEvent) {
  event.preventDefault();
  event.returnValue = "";
}
