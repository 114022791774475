export const parseQueryString = (
  queryString = window.location.search.substring(1)
): { [key: string]: string } =>
  Object.assign(
    {},
    ...queryString
      .split("&")
      .map((pair) => pair.split("="))
      .map((pair) => {
        return pair[0] ? { [pair[0]]: decodeURIComponent(pair[1]!) } : {};
      })
  );
