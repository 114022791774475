import { StorageService } from "@fidget/common/services/storageService";
import { isPersistedState, PersistedState, persistedStore } from "../models/persisted-store";

export function createStorageService(orgId: string): StorageService<PersistedState> {
  return new StorageService(
    orgId,
    persistedStore,
    isPersistedState,
    "persistedState",
  );
}
