import { getCobrowse } from "./cobrowseScript";

export interface CobrowseIO {
  confirmSession: () => Promise<void>;
  customData: {
    device_id?: string;
  };
  currentSession?: {
    end: () => void;
  };
  showSessionControls: () => void;
  hideSessionControls: () => void;
}

export const getTypedCobrowseIO = getCobrowse as (licenseKey: string) => Promise<CobrowseIO>;
