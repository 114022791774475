export enum SystemTag {
  ReturningVisitor = "returning_visitor",
  NewVisitor = "new_visitor",
  ExitIntent = "exit_intent",
  Bot = "bot"
}

export const isSystemTag = (tag: string): boolean => {
  return !!Object.values(SystemTag).find((t) => tag === t);
};
