import { getTypedCobrowseIO, CobrowseIO } from "@fidget/common/utils/typedCobrowseIO";
export class CobrowseService {
  private cobrowseIO?: CobrowseIO;
  private sessionButton?: HTMLElement;

  constructor(
    private cobrowseLicenseKey: string,
    private onClientEnd: () => void
  ) { }

  public initializeSession(sessionId: string): Promise<void> {
    if (this.cobrowseIO === undefined) {
      return getTypedCobrowseIO(this.cobrowseLicenseKey)
        .then((cobrowseObject: CobrowseIO) => {
          this.sessionButton = this.createSessionButton();
          this.cobrowseIO = cobrowseObject;
          this.cobrowseIO.confirmSession = () => Promise.resolve();
          this.cobrowseIO.showSessionControls = () => this.showSessionButton();
          this.cobrowseIO.hideSessionControls = () => this.hideSessionButton();
        })
        .then(() => this.identifySession(sessionId));
    }
    this.identifySession(sessionId);
    return Promise.resolve();
  }

  public endSession(): void {
    if (this.cobrowseIO !== undefined && this.cobrowseIO.currentSession !== undefined) {
      this.cobrowseIO.currentSession.end();
    }
  }

  private onEndSessionButtonClick(): void {
    this.endSession();
    this.onClientEnd();
  }

  private createSessionButton(): HTMLElement {
    const button = document.createElement("div");
    button.className = "__cbio_ignored";
    button.textContent = "End cobrowsing session";
    button.style.fontFamily = "sans-serif";
    button.style.padding = "10px 13px";
    button.style.fontSize = "13px";
    button.style.color = "white";
    button.style.boxShadow = "0px 2px 5px #33333344";
    button.style.cursor = "pointer";
    button.style.borderRadius = "30px";
    button.style.background = "#FF3B57";
    button.style.position = "fixed";
    button.style.zIndex = "2147483647";
    button.style.bottom = "20px";
    button.style.left = "50%";
    button.style.transform = "translateX(-50%)";
    button.style.display = "none";
    button.addEventListener("click", () => this.onEndSessionButtonClick());
    document.body.appendChild(button);
    return button;
  }

  private showSessionButton(): void {
    if (this.sessionButton) {
      this.sessionButton.style.display = "block";
    }
  }

  private hideSessionButton(): void {
    if (this.sessionButton) {
      this.sessionButton.style.display = "none";
    }
  }

  private identifySession(sessionId: string): void {
    if (this.cobrowseIO !== undefined) {
      this.cobrowseIO.customData = {
        device_id: sessionId,
      };
    }
  }

}
