import { OrgConfig } from "@closerplatform/spinner-openapi";
import { IncomingMessage } from "@fidget/common/models/incomingMessage";
import { Language } from "@fidget/common/models/language";
import { Observable } from "../utils/observable";

export class Model {
  public readonly $orgConfig = new Observable<OrgConfig | undefined>(undefined);
  public readonly $incomingMessages = new Observable<ReadonlyArray<IncomingMessage>>([]);
  public readonly $chatIsVisible: Observable<boolean>;

  constructor(public readonly orgId: string, public readonly language: Language, isChatVisibleInitialValue: boolean) {
    this.$chatIsVisible = new Observable(isChatVisibleInitialValue);
  }
}
