import { LogData, Logger, LogInput, LogLevel, UserLogContext } from "@fidget/common/utils/logger/types";
import { sequentialize } from "@fidget/common/utils/sequentialize";
import { getFingerprint as loadFingerprint } from "@thumbmarkjs/thumbmarkjs";
import { uuidv4 } from "../uuidv4";
import { getVersion } from "@fidget/common/utils/version";

export class ServerLogger extends Logger {
  private level?: LogLevel = LogLevel.debug;
  private ready = false;
  private baseUrl: string;
  private traceId: string;
  private app?: string;
  private userLogContext?: UserLogContext;
  private logs: Array<any> = [];
  private seq = 0;
  private levelSeq: Record<string, number> = {};
  private version: string;

  constructor(config?: {baseUrl?: string, traceId?: string, app?: string}) {
    super();
    const {baseUrl, traceId, app} = config ?? {};
    this.baseUrl = baseUrl ?? "";
    this.traceId = traceId ?? uuidv4();
    this.app = app;
    this.version = getVersion();

    loadFingerprint(false)
      .then((visitorId) => this.doLog({ timestamp: Date.now(), message: "logger init", visitorId } as LogData, LogLevel.info));

    const nextHandler = window.onerror;
    window.onerror = (message, url, line, column, error) => {
      this.doLog({ timestamp: Date.now(), message: JSON.stringify({ message, url, line, column }), error }, LogLevel.error);
      return nextHandler ? nextHandler(message, url, line, column, error) : false;
    };
    window.addEventListener("error", (event) => this.doLog({
      ...event,
      timestamp: Date.now(),
    }, LogLevel.error));
    // window.addEventListener("beforeunload", () => this.sendLogs());
    window.addEventListener("unload", () => this.sendLogsImmediately(true));
  }

  private async sendLogsImmediately(keepalive = false) {
    if (this.logs.length) {
      const logs = this.logs.filter((e) => this.level && (!this.ready || LogLevel[e.level as keyof typeof LogLevel] >= this.level));
      this.logs.length = 0;
      if (logs.length) {
        await fetch(`${this.baseUrl}/api/q`, {
          method: "POST",
          body: JSON.stringify({ logs }),
          headers: {
            "Content-Type": "application/json"
          },
          keepalive,
        });
      }
    }
  }

  private sendLogs = sequentialize(() => this.sendLogsImmediately());

  private doLog(arg: LogInput, level: LogLevel, ...args: unknown[]): void {
    if (!(level in this.levelSeq)) {
      this.levelSeq[level] = 0;
    }
    this.logs.push({
      ...this.parseLogInput(arg),
      args,
      level: LogLevel[level],
      ...this.userLogContext,
      traceId: this.traceId,
      url: window.location.href,
      stack: Error().stack,
      seq: this.seq++,
      levelSeq: this.levelSeq[level]++,
      app: this.app,
      version: this.version,
    });
  }

  debug(data: LogInput, ...args: unknown[]): void {
    this.doLog(data, LogLevel.debug, ...args);
  }

  info(data: LogInput, ...args: unknown[]): void {
    this.doLog(data, LogLevel.info, ...args);
  }

  warn(data: LogInput, ...args: unknown[]): void {
    this.doLog(data, LogLevel.warn, ...args);
  }

  error(data: LogInput, ...args: unknown[]): void {
    this.doLog(data, LogLevel.error, ...args);
  }

  public setLevel(level?: LogLevel): void {
    this.ready = true;
    this.level = level;

    const everySecond = setInterval(() => this.sendLogs(), 1000);
    setTimeout(() => {
      clearInterval(everySecond);
      setInterval(() => this.sendLogs(), 10000);
    }, 10000);
  }

  setUserLogContext(context: UserLogContext): void {
    this.userLogContext = context;
  }

  clearUserLogContext(): void {
    this.userLogContext = undefined;
  }

}
