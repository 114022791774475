type ObjStack = Array<{
  key: string;
  obj: any;
}>;

type Result = Array<{
  key: string;
  type: string;
}>;

function keys(obj: any): Array<string> {
  const objStack: ObjStack = [{ key: "", obj }];
  const result: Result = [];
  while (objStack.length) {
    const element = objStack.pop()!;
    const typeOfObject = typeof element.obj;
    if (Array.isArray(element.obj)) {
      result.push({ key: element.key, type: "array" });
      objStack.push({ key: element.key + ".0", obj: element.obj[0] });
    } else if (typeOfObject === "object") {
      result.push({ key: element.key, type: typeOfObject });
      for (let prop in element.obj) {
        if (element.obj.hasOwnProperty(prop)) {
          objStack.push({ key: element.key + "." + prop, obj: element.obj[prop] });
        }
      }
    } else {
      result.push({ key: element.key, type: typeOfObject !== "undefined" ? typeOfObject : "" });
    }
  }
  return result
    .sort((a, b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0))
    .map((o) => o.key + (o.type ? ":" + o.type : ""));
}

export const match = (value: any, pattern: any): boolean => {
  const valueKeys = keys(value);
  let valueKeysCounter = 0;
  const patternKeys = keys(pattern);
  let patternKeysCounter = 0;
  while (true) {
    if (patternKeysCounter >= patternKeys.length) {
      return true;
    }
    if (valueKeysCounter >= valueKeys.length) {
      return false;
    }
    const valueKey = valueKeys[valueKeysCounter];
    const patternKey = patternKeys[patternKeysCounter];
    if (
      valueKey === patternKey ||
      valueKey!.split(":")[0] === patternKey ||
      valueKey === patternKey!.split(":")[0]
    ) {
      patternKeysCounter++;
    } else {
      valueKeysCounter++;
    }
  }
};
