import { OrgConfig } from "@closerplatform/spinner-openapi";
import { AuthorizationParameters } from "@fidget/common/models/AuthorizationParameters";
import { IBreakingPointsInfo } from "@fidget/common/models/breakingPoints";
import { IBrowserWindowSize } from "@fidget/common/models/browserWindowSize";
import { CurrentPage } from "@fidget/common/models/currentPage";
import { IncomingMessage } from "@fidget/common/models/incomingMessage";
import { UserAuthorizationResult } from "@fidget/common/models/userAuthorizationResult";
import { ProxyIframe, WindowProxy } from "@fidget/common/services/windowProxyFactory";
import { Config } from "@fidget/common/config/config";
import { WindowRequestUserDetails } from "@fidget/common/models/window-requests-user-details";
import { TagsChangedDetails } from "@fidget/common/models/tags-changed-details";
import { UrlTagsProactiveMessages } from "@fidget/common/models/url-tags-proactive-messages";
import { Credentials } from "@fidget/common/models/credentials";
import { ConversationStatus } from "@fidget/common/models/api-config";

export interface PickerWidgetMessages {
  [question: string]: [string];
}

export class ForeWindowRequestsService {
  public readonly foreWindow: WindowProxy;
  public readonly iframe: HTMLIFrameElement;

  constructor(proxyIframe: ProxyIframe) {
    this.foreWindow = proxyIframe.proxy;
    this.iframe = proxyIframe.iframe;
  }

  /**
   * Indicates that fore window is loaded and we can start sending requests
   */
  onForeInitialized(fn: (config: Config) => void) {
    return this.foreWindow.onRequest<Config, void>("widgetReady", config => {
      fn(config);
      return Promise.resolve();
    });
  }

  onTagsChanged(fn?: (tags: TagsChangedDetails) => void) {
    return this.foreWindow.onRequest<TagsChangedDetails, void>("tagsChanged", (tags) => {
      if (fn) {
        fn(tags);
      }
      return Promise.resolve();
    });
  }

  onDeinitCommand(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<void, void>("deinitCommand", fn);
  }

  onMaximizeWidget(fn: () => void) {
    return this.foreWindow.onRequest<void, void>("maximizeWidget", () => {
      fn();
      return Promise.resolve();
    });
  }

  onMaintenanceModeEnabled(fn: (message: string) => Promise<void>) {
    return this.foreWindow.onRequest<string, void>("maintenanceModeEnabled", fn);
  }

  onUserAuthorizationSuccess(fn: (authorizationResult: UserAuthorizationResult) => Promise<void>) {
    return this.foreWindow.onRequest<UserAuthorizationResult, void>("userAuthorizationSuccess", fn);
  }

  onUserAuthorizationFailed(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<void, void>("userAuthorizationFailed", fn);
  }

  onGetAuthorizationParameters(fn: () => Promise<AuthorizationParameters>) {
    return this.foreWindow.onRequest<void, AuthorizationParameters>("getAuthorizationParameters", fn);
  }

  onGetLoginHintToken(fn: () => Promise<string>) {
    return this.foreWindow.onRequest<void, string>("getLoginHintToken", fn);
  }

  onWidgetInitialized(fn: (success: boolean) => Promise<void>) {
    return this.foreWindow.onRequest<boolean, void>("widgetInitialized", fn);
  }

  onOrgConfig(fn: (orgConfig: OrgConfig) => Promise<void>) {
    return this.foreWindow.onRequest<OrgConfig, void>("orgConfig", fn);
  }

  identifyUser(user: WindowRequestUserDetails) {
    return this.foreWindow.sendRequest<WindowRequestUserDetails, void>("identifyUser", user, true);
  }

  onHideWidget(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<void, void>("hideWidget", fn);
  }

  setSideBarMode(mode: boolean) {
    return this.foreWindow.sendRequest<boolean, void>("sideBarMode", mode);
  }

  onIncomingMessage(fn: (msg: IncomingMessage) => Promise<void>) {
    return this.foreWindow.onRequest<IncomingMessage, void>("incomingMessage", fn);
  }

  chatVisibilityChanged(args: {visible: boolean, initializeBeforeRender: boolean}) {
    return this.foreWindow.sendRequest<{visible: boolean, initializeBeforeRender: boolean}, void>("chatVisibility", args);
  }

  onReloadNeeded(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<void, void>("reload", fn);
  }

  getUrlTagsProactiveMessages() {
    return this.foreWindow.sendRequest<void, UrlTagsProactiveMessages>("urlTagsProactiveMessages");
  }

  currentPageChanged(currentPage: CurrentPage) {
    return this.foreWindow.sendRequest<CurrentPage, void>("currentPageChanged", currentPage);
  }

  windowResized(sizeInfo: { breakingPoints: IBreakingPointsInfo; browserWindowSize: IBrowserWindowSize; }) {
    return this.foreWindow.sendRequest<{ breakingPoints: IBreakingPointsInfo; browserWindowSize: IBrowserWindowSize; }, void>(
      "windowResized",
      sizeInfo
    );
  }

  tabActiveChanged(tabIsActive: boolean) {
    return this.foreWindow.sendRequest<boolean, void>("tabActive", tabIsActive);
  }

  onUnreadMessagesChanged(fn: (isUnread: boolean) => Promise<void>) {
    return this.foreWindow.onRequest<boolean, void>("isUnread", fn);
  }

  onSetIframeWidth(fn: (agentVideoExpanded: boolean) => Promise<void>) {
    return this.foreWindow.onRequest<boolean, void>("agentVideoExpanded", fn);
  }

  openWidgetWithAutoCalling(autocall: boolean) {
    return this.foreWindow.sendRequest<boolean, void>("autocall", autocall);
  }

  forgetClientRequest(reinit = false) {
    return this.foreWindow.sendRequest<boolean, void>("forgetClient", reinit);
  }

  onMessageSent(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<undefined, void>("messageSent", fn);
  }

  onMessageReceived(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<undefined, void>("messageReceived", fn);
  }

  setProactiveMessages(pickerWidgetMessages: PickerWidgetMessages) {
    return this.foreWindow.sendRequest<PickerWidgetMessages, void>("pickerWidgetMessages", pickerWidgetMessages);
  }

  onCobrowsingSessionInitialized(fn: (sessionId: string) => Promise<void>) {
    return this.foreWindow.onRequest<string, void>("cobrowsingSessionInitialized", fn);
  }

  onCobrowsingSessionEnded(fn: () => Promise<void>) {
    return this.foreWindow.onRequest<void, void>("cobrowsingSessionEnded", fn);
  }

  clientEndedCobrowsing() {
    return this.foreWindow.sendRequest<void, void>("clientEndedCobrowsing");
  }

  sendUrlParams(params: { [key: string]: string; }) {
    return this.foreWindow.sendRequest<{ [key: string]: string; }, void>("urlParams", params);
  }

  getCredentials() {
    return this.foreWindow.sendRequest<void, Credentials>("getCredentials");
  }

  onAgentAvatarChanged(fn: (url?: string) => Promise<void>) {
    return this.foreWindow.onRequest<string | undefined, void>("onAgentAvatarChanged", fn);
  }

  onLektaContext(fn: (context: any) => Promise<void>) {
    return this.foreWindow.onRequest<any, void>("onLektaContext", fn);
  }

  onStorageGetItem(fn: (key: string) => Promise<string | undefined>) {
    return this.foreWindow.onRequest<string, string | undefined>("storageGetItem", fn);
  }

  onStorageSetItem(fn: (args: {key: string, value: string}) => Promise<void>) {
    return this.foreWindow.onRequest<{key: string, value: string}, void>("storageSetItem", fn);
  }

  onStorageRemoveItem(fn: (key: string) => Promise<void>) {
    return this.foreWindow.onRequest<string, void>("storageRemoveItem", fn);
  }

  onConversationStatusChanged(fn: (status: ConversationStatus) => Promise<void>) {
    return this.foreWindow.onRequest<any, void>("conversationStatusChanged", fn);
  }
}
