import { ApiConfig } from "@fidget/common/models/api-config";
import { UserDetails } from "@fidget/common/models/user-details";
import { Api, ApiCall } from "@fidget/common/models/widget-api";
import { logger } from "@fidget/common/utils";
import { ResidentServiceFactory } from "./factories/resident-service.factory";
import { ResidentService } from "./services/resident.service";
import stringify from "safe-json-stringify";

export class CloserApi implements Api {
  private residentServicePromise?: Promise<ResidentService>;
  private residentService?: ResidentService;

  private isWidgetInitialized = false;
  private isWidgetReady = false;

  private broadcastChannel = new BroadcastChannel("closer");

  constructor(public q: ApiCall[], public scriptUrl?: string) {
    logger.debug(`CloserApi(${stringify(arguments)})`);
    this.broadcastChannel.onmessage = (message) => {
      if ("deinit" in message.data) {
        this.performDeinit(message.data.deinit);
      }
    };
  }

  private broadcastDeinit(reinit: boolean) {
    logger.debug(`CloserApi.broadcastDeinit(${stringify(arguments)})`);
    this.broadcastChannel.postMessage({deinit: reinit});
  }

  public async init(apiConfig: ApiConfig): Promise<void> {
    logger.debug(`CloserApi.init(${stringify(arguments)})`);

    const { orgId } = apiConfig;

    if (this.residentServicePromise) {
      throw new Error("Closer is already initialized, deinitialize first");
    }

    if (!orgId) {
      throw new Error("`orgId` configuration parameter is required");
    }

    const residentServiceFactory = new ResidentServiceFactory(
      orgId,
      apiConfig,
      () => this.onWidgetReady(),
      () => this.onWidgetInitialized(),
      () => this.deinitExternal(),
      this.scriptUrl
    );

    this.residentServicePromise = residentServiceFactory.create();

    try {
      this.residentService = await this.residentServicePromise;
      this.residentService.initializeWidgetButtonContainer();
    } catch (e) {
      logger.error(`Failed to initialize closer widget with error: ${e}`);
    }
  }

  public async reinit(config: ApiConfig): Promise<void> {
    logger.debug(`CloserApi.reinit(${stringify(arguments)})`);

    const { orgId } = config;
    if (!orgId) {
      throw new Error("`orgId` configuration parameter is required");
    }

    if (this.isWidgetInitialized) {
      await this.deinit(true);
    }

    logger.debug("Closer: Starting reinit");

    await this.init({ ...config, reinitParameters: { enabled: true } });

    this.openWidget();
  }

  public identify(userDetails?: UserDetails): void {
    logger.debug(`CloserApi.identify(${stringify(arguments)})`);

    if (!userDetails) {
      userDetails = {};
    }

    if (!this.residentServicePromise) {
      throw new Error("Closer identify: initialize the widget first");
    }

    if (this.isWidgetInitialized && this.residentService) {
      this.residentService.identifyUser(userDetails);
    } else {
      this.q = [...this.q, { method: "identify", args: [userDetails] }];
    }
  }

  public openWidget(arg?: string): void {
    logger.debug(`CloserApi.openWidget(${stringify(arguments)})`);

    if (!this.residentServicePromise) {
      throw new Error("Closer openWidget: initialize the widget first");
    }

    if (this.isWidgetReady && this.residentService) {
      this.residentService.openWidget(arg);
    } else {
      this.q = [...this.q, { method: "openWidget", args: [arg] }];
    }
  }

  public forgetClient(): void {
    logger.debug(`CloserApi.forgetClient(${stringify(arguments)})`);

    this.getResidentServiceSafely().forgetClient();
  }

  private performDeinit(reinit = false): Promise<void> {
    logger.debug(`CloserApi.performDeinit(${stringify(arguments)})`);

    return this.getResidentServiceSafely()
      .deinit(reinit)
      .finally(() => this.deinitExternal());
  }

  public deinit(reinit = false): Promise<void> {
    logger.debug(`CloserApi.deinit(${stringify(arguments)})`);

    this.broadcastDeinit(reinit);
    return this.performDeinit(reinit);
  }

  public log(arg: any): void {
    logger.debug(`CloserApi.log(${stringify(arguments)})`);
    logger.info(arg);
  }

  public showButton(): void {
    logger.debug(`CloserApi.showButton(${stringify(arguments)})`);

    this.getResidentServiceSafely().showButton();
  }

  public hideButton(): void {
    logger.debug(`CloserApi.hideButton(${stringify(arguments)})`);

    this.getResidentServiceSafely().hideButton();
  }

  public getResidentServiceSafely(): ResidentService {
    logger.debug(`CloserApi.getResidentServiceSafely(${stringify(arguments)})`);

    if (!this.residentServicePromise) {
      throw new Error("Closer: initialize the widget first");
    }

    if (!this.residentService) {
      throw new Error("Closer: widget is not yet initialized");
    }

    return this.residentService;
  }

  private deinitExternal() {
    logger.debug(`CloserApi.deinitExternal(${stringify(arguments)})`);

    this.residentServicePromise = undefined;
    this.isWidgetInitialized = false;
    this.isWidgetReady = false;
  }

  private onWidgetReady(): void {
    logger.debug(`CloserApi.onWidgetReady(${stringify(arguments)})`);

    this.isWidgetReady = true;
    this.processOpenWidgetQueue();
  }

  private onWidgetInitialized(): void {
    logger.debug(`CloserApi.onWidgetInitialized(${stringify(arguments)})`);

    this.isWidgetInitialized = true;
    this.processIdentifyQueue();
  }

  private processOpenWidgetQueue(): void {
    logger.debug(`CloserApi.processOpenWidgetQueue(${stringify(arguments)})`);

    return this.q
      .filter(apiCall => apiCall.method === "openWidget")
      .map(identify => identify.args[0])
      .forEach(identifyArg => this.openWidget(identifyArg));
  }

  private processIdentifyQueue(): void {
    logger.debug(`CloserApi.processIdentifyQueue(${stringify(arguments)})`);

    return this.q
      .filter(apiCall => apiCall.method === "identify")
      .map(identify => identify.args[0])
      .forEach(identifyArg => this.identify(identifyArg));
  }
}
