export interface IBreakingPointsInfo {
  isMobile: boolean;
  shouldCallDrawerBeHidden: boolean;
}

export const getBreakingPoints = () => {
  const breakingPoints = {
    isMobile: window.innerWidth <= 500 || window.innerHeight <= 580,
    shouldCallDrawerBeHidden: window.innerWidth < 798 || window.innerHeight <= 580
  };
  return breakingPoints;
};
