import { Language } from "@fidget/common/models/language";

export class ViewTexts {
  constructor(private readonly language: Language) {}

  public getMessageFromLabel(): string {
    return this.language === Language.PL
      ? "Nowa wiadomość od: "
      : "New Message from ";
  }

  public getTooltipText(callsDisabled: boolean): string {
    const tooltipVersion = callsDisabled ? 1 : 0;
    const polishTooltips = [
      "Kliknij, by zadzwonić lub rozpocząć czat",
      "Kliknij, by rozpocząć czat",
    ];
    const englishTooltips = [
      "Click to call or chat with us",
      "Click to chat with us",
    ];
    return this.language === Language.PL
      ? polishTooltips[tooltipVersion]!
      : englishTooltips[tooltipVersion]!;
  }

  public getAdviserLabel(): string {
    return this.language === Language.PL ? "Doradca" : "Adviser";
  }
}
