import { $$ } from "@fidget/common/utils/domHelper";
import { ViewTexts } from "./viewTexts";
import { ViewStyles } from "./viewStyles";
import styles from "../assets/view.css";
import { OrgConfig } from "@closerplatform/spinner-openapi";
import { defaultOrgColor, setOrgColor } from "@fidget/common/utils/setOrgColor";

export class View {
  private isExpanded = false;

  constructor(
    private readonly viewTexts: ViewTexts,
    private readonly chatIsFloating: boolean,
    private readonly topLevelContainer: HTMLElement,
    private readonly messageListContainer: HTMLElement,
    private readonly mainButton: HTMLElement,
    private readonly mainButtonTooltip: HTMLElement,
    private readonly mainButtonAvatar: HTMLElement,
    private readonly mainWrapper: HTMLElement,
    private readonly iframeContainer: HTMLElement,
    private readonly foreIframe: HTMLIFrameElement
  ) {}

  public setChatIsVisible(visible: boolean) {
    View.toggleClass(document.body, ViewStyles.bodyWidgetVisibleClass, visible);
    View.toggleClass(this.iframeContainer, styles.hidden, !visible);
    View.toggleClass(
      this.mainButton,
      styles.hidden,
      !this.chatIsFloating && visible
    );
    View.toggleClass(this.mainButton, styles.close, visible);
    View.toggleClass(
      this.mainWrapper,
      styles["dark-background"],
      visible && this.isExpanded
    );
  }

  public render({ callsDisabled, color }: OrgConfig): void {
    this.hideMainButton();
    this.mainButtonTooltip.innerText = this.viewTexts.getTooltipText(
      callsDisabled || false
    );

    const mainColor = color || defaultOrgColor;

    setOrgColor(mainColor);
    if (!callsDisabled) {
      import("./animateCloserLogo").then((module) => {
        module.animateCloserSymbol("main_button_open", mainColor);
      });
    }
    this.topLevelContainer.style.display = "block";
  }

  public showMainButton(): void {
    this.mainButton.style.display = "block";
  }

  public hideMainButton(): void {
    this.mainButton.style.display = "none";
  }

  public setExpandedIframeWidth(isExpanded: boolean) {
    this.isExpanded = isExpanded;
    const iframeWidth = isExpanded
      ? this.foreIframe.clientHeight * 1.25 + 376
      : 376;
    this.foreIframe.setAttribute("style", `width: ${iframeWidth}px`);

    View.toggleClass(this.mainWrapper, styles["dark-background"], isExpanded);
  }

  public clearMessages() {
    [].slice
      .call(
        this.messageListContainer.querySelectorAll("." + styles["message-wrap"])
      )
      .forEach((msg: HTMLElement) => msg.parentNode!.removeChild(msg));
  }

  public addMessage(
    text: string,
    onClick: () => void,
    agentFirstName?: string
  ) {
    const { newMessage, overflowGradient } = $$(
      "div",
      { classList: [styles["message-wrap"]], ref: "newMessage" },
      [
        $$("div", { classList: [styles["from-message"]] }, [
          {
            $: document.createTextNode(
              this.viewTexts.getMessageFromLabel() + agentFirstName || ""
            ),
          },
        ]),
        $$("div", { classList: [styles.message] }, [
          { $: document.createTextNode(text) },
        ]),
        $$("div", {
          classList: [styles["overflow-gradient"], styles.hidden],
          ref: "overflowGradient",
        }),
      ]
    );
    newMessage!.addEventListener("click", onClick);
    this.messageListContainer.insertBefore(
      newMessage!,
      this.messageListContainer.childNodes[0]!
    );
    if (
      newMessage!.scrollHeight - newMessage?.clientHeight! >
      ViewStyles.messageOverFlowMargin
    ) {
      View.toggleClass(overflowGradient!, styles.hidden, false);
    }
  }

  public showAgentAvatar(url: string) {
    this.mainButtonAvatar.style.display = "block";
    this.mainButtonAvatar.style.backgroundImage = `url(${url})`;
  }

  public hideAgentAvatar() {
    this.mainButtonAvatar.style.display = "none";
  }

  private static toggleClass(el: Element, className: string, add = true) {
    el.className = el.className
      .split(" ")
      .map((cls) => cls.trim())
      .filter((c) => c !== className)
      .concat(add ? [className] : [])
      .join(" ");
  }
}
